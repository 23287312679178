import configureStore, { AppDispatch } from './configureStore';
import configureUserModule, { UserActions, UserReducer } from './user';
import configureClientModule, { TopUpActions, TopUpReducer } from './topUp';
import configureBuyAndPrintModule, { BuyAndPrintActions, BuyAndPrintReducer } from './buyAndPrint';
import configureBuyModule, { BuyActions, BuyReducer } from './buy';
import configureTopUpHistoryModule, { TopUpHistoryActions, TopUpHistoryReducer } from './topUpHistory';
import configureAssignBundleHistoryModule, { AssignBundleHistoryActions, AssignBundleHistoryReducer } from './assignBundleHistory';
import configurePrintHistoryModule, { PrintHistoryActions, PrintHistoryReducer } from './printHistory';
import configureWalletModule, { WalletActions, WalletReducer } from './wallet';
import configureCommissionModule, { CommissionActions, CommissionReducer } from './commission';
import configureAlertsModule, { AlertsActions, AlertsReducer } from './alerts';
import configureLocalizationModule, { LocalizationActions, LocalizationReducer } from './localization';
import configureItemModule, { ItemActions, ItemReducer } from './item';
import configureTilesModule, { DashboardTilesActions, DashboardTilesReducer } from './dashboardTiles';
import configureStandaloneTopUpModule, { StandaloneTopUpActions, StandaloneTopUpReducer } from './StandaloneTopUp';
import { IServices } from '@src/services';
import { History } from 'history';
import configureForgottenPasswordModule, { ForgottenPasswordActions, ForgottenPasswordReducer } from './forgottenPassword';
import automation from './automation';
import configureMyOrdersModule, { MyOrdersActions, MyOrdersReducer } from './myOrders';
import configureAnalyticsModule, { AnalyticsActions, AnalyticsReducer } from './analytics';
import configureWalletHistoryModule, { WalletHistoryActions, WalletHistoryReducer } from './walletHistory';
import configureSuperInvoiceModule, { SuperInvoiceActions, SuperInvoiceReducer } from './superInvoice';
import configureInvoiceHistoryModule, { InvoiceHistoryActions, InvoiceHistoryReducer } from './invoiceHistory';
import configureTradeOffersModule, { TradeOffersActions, TradeOffersReducer } from './tradeOffers';
import configureDownloadCentreModule, { DownloadCentreActions, DownloadCentreReducer } from './downloadCentre';
import configurePostPayModule, { PostPayActions, PostPayReducer } from './postPay';
import configureKpiReportModule, { KpiReportActions, KpiReportReducer } from './kpiReport';
import configureContractStatusModule, { ContractStatusActions, ContractStatusReducer } from './contractStatus';
import configurePortInResubmitModule, { PortInResubmitReducer, PortInResubmitActions } from './portInResubmission'
import configureSimRegAssessmentModule, { SimRegAssessmentActions, SimRegAssessmentReducer } from './SimRegAssessment';
import configureFaqModule, { FaqActions, FaqReducer } from './faq';
import configureSigaSimRegModule, { SigaSimRegAction, SigaSimRegReducer } from './sigaSimRegistration';
import configureEmployeeModule, { EmployeeActions, EmployeeReducer } from './employees';
import configurePrepaidPortalModule, { PrepaidPortalActions } from './prepaidPortal';
import configureMigrationModule, { MigrationAction, MigrationReducer } from './migration'
import { Observable } from 'rxjs';
import configureSimOnlyModule, { SimOnlyActions, SimOnlyReducer } from './simonly';
declare global {
  var serviceActions: Actions;
}

const configureModules = async (services: IServices, history: History<unknown>, i18n: any) => {
  const userModule = configureUserModule(services, history);
  const clientModule = configureClientModule(services);
  const buyAndPrintModule = configureBuyAndPrintModule(services);
  const buyModule = configureBuyModule(services);
  const topUpHistoryModule = configureTopUpHistoryModule(services);
  const assignBundleHistoryModule = configureAssignBundleHistoryModule(services);
  const printHistoryModule = configurePrintHistoryModule(services);
  const walletModule = configureWalletModule(services);
  const commissionModule = configureCommissionModule(services);
  const itemModule = configureItemModule(services);
  const localizationModule = configureLocalizationModule(services, i18n);
  const forgottenPasswordModule = configureForgottenPasswordModule(services);
  const alertsModule = configureAlertsModule(services);
  const dashboardTilesModule = configureTilesModule(services);
  const standaloneTopUpModule = configureStandaloneTopUpModule(services);
  const myOrdersModule = configureMyOrdersModule(services);
  const analyticsModule = configureAnalyticsModule(history);
  const walletHistory = configureWalletHistoryModule(services);
  const superInvoice = configureSuperInvoiceModule(services);
  const invoiceHistory = configureInvoiceHistoryModule(services)
  const tradeOffers = configureTradeOffersModule(services);
  const downloadCentre = configureDownloadCentreModule(services);
  const postPay = configurePostPayModule(services);
  const kpiReport = configureKpiReportModule(services);
  const contractStatus = configureContractStatusModule(services);
  const portInResubmit = configurePortInResubmitModule(services)
  const simRegAssessment = configureSimRegAssessmentModule(services);
  const sigaSimReg = configureSigaSimRegModule(services)
  const faq = configureFaqModule(services);
  const employees = configureEmployeeModule(services);
  const prepaidPortal = configurePrepaidPortalModule(services);
  const migration = configureMigrationModule(services);
  const simonly = configureSimOnlyModule(services)

  window.serviceActions = {
    user: userModule.actions,
    topUp: clientModule.actions,
    buyAndPrint: buyAndPrintModule.actions,
    buy: buyModule.actions,
    topUpHistory: topUpHistoryModule.actions,
    assignBundleHistory: assignBundleHistoryModule.actions,
    printHistory: printHistoryModule.actions,
    wallet: walletModule.actions,
    commission: commissionModule.actions,
    items: itemModule.actions,
    localization: localizationModule.actions,
    forgottenPassword: forgottenPasswordModule.actions,
    alerts: alertsModule.actions,
    dashboardTiles: dashboardTilesModule.actions,
    standaloneTopUp: standaloneTopUpModule.actions,
    myOrders: myOrdersModule.actions,
    analytics: analyticsModule.actions,
    walletHistory: walletHistory.actions,
    invoiceHistory: invoiceHistory.actions,
    superInvoice: superInvoice.actions,
    tradeOffers: tradeOffers.actions,
    downloadCentre: downloadCentre.actions,
    postPay: postPay.actions,
    kpiReport: kpiReport.actions,
    contractStatus: contractStatus.actions,
    portInResubmit: portInResubmit.actions,
    sigaSimReg: sigaSimReg.actions,
    simRegAssessment: simRegAssessment.actions,
    faq: faq.actions,
    employees: employees.actions,
    prepaidPortal: prepaidPortal.actions,
    migration: migration.actions,
    simonly: simonly.actions
  }

  const store = configureStore({
    user: userModule.reducer,
    topUp: clientModule.reducer,
    buyAndPrint: buyAndPrintModule.reducer,
    buy: buyModule.reducer,
    topUpHistory: topUpHistoryModule.reducer,
    assignBundleHistory: assignBundleHistoryModule.reducer,
    printHistory: printHistoryModule.reducer,
    wallet: walletModule.reducer,
    commission: commissionModule.reducer,
    items: itemModule.reducer,
    localization: localizationModule.reducer,
    forgottenPassword: forgottenPasswordModule.reducer,
    alerts: alertsModule.reducer,
    dashboardTiles: dashboardTilesModule.reducer,
    standaloneTopUp: standaloneTopUpModule.reducer,
    myOrders: myOrdersModule.reducer,
    analytics: analyticsModule.reducer,
    walletHistory: walletHistory.reducer,
    superInvoice: superInvoice.reducer,
    invoiceHistory: invoiceHistory.reducer,
    tradeOffers: tradeOffers.reducer,
    downloadCentre: downloadCentre.reducer,
    postPay: postPay.reducer,
    kpiReport: kpiReport.reducer,
    contractStatus: contractStatus.reducer,
    portInResubmit: portInResubmit.reducer,
    simRegAssessment: simRegAssessment.reducer,
    faq: faq.reducer,
    sigaSimReg: sigaSimReg.reducer,
    employees: employees.reducer,
    migration: migration.reducer,
    simonly: simonly.reducer
  }, history);

  store.subscribe(automation(window.serviceActions, store, i18n, history, services.interceptorConnector))

  return store;
};

export type Reducers = {
  user: UserReducer
  topUp: TopUpReducer
  buyAndPrint: BuyAndPrintReducer
  buy: BuyReducer
  topUpHistory: TopUpHistoryReducer
  assignBundleHistory: AssignBundleHistoryReducer
  printHistory: PrintHistoryReducer
  wallet: WalletReducer
  commission: CommissionReducer
  items: ItemReducer
  localization: LocalizationReducer
  forgottenPassword: ForgottenPasswordReducer,
  alerts: AlertsReducer,
  dashboardTiles: DashboardTilesReducer,
  standaloneTopUp: StandaloneTopUpReducer,
  myOrders: MyOrdersReducer,
  analytics: AnalyticsReducer,
  walletHistory: WalletHistoryReducer,
  superInvoice: SuperInvoiceReducer,
  tradeOffers: TradeOffersReducer,
  invoiceHistory: InvoiceHistoryReducer,
  downloadCentre: DownloadCentreReducer,
  postPay: PostPayReducer,
  kpiReport: KpiReportReducer,
  portInResubmit: PortInResubmitReducer,
  contractStatus: ContractStatusReducer,
  simRegAssessment: SimRegAssessmentReducer,
  faq: FaqReducer,
  sigaSimReg: SigaSimRegReducer,
  employees: EmployeeReducer,
  migration: MigrationReducer,
  simonly: SimOnlyReducer
}

export type Actions = {
  user: UserActions
  topUp: TopUpActions
  buyAndPrint: BuyAndPrintActions
  buy: BuyActions
  topUpHistory: TopUpHistoryActions
  assignBundleHistory: AssignBundleHistoryActions
  printHistory: PrintHistoryActions
  wallet: WalletActions
  commission: CommissionActions
  items: ItemActions
  localization: LocalizationActions
  forgottenPassword: ForgottenPasswordActions,
  alerts: AlertsActions,
  dashboardTiles: DashboardTilesActions,
  standaloneTopUp: StandaloneTopUpActions
  myOrders: MyOrdersActions,
  analytics: AnalyticsActions,
  walletHistory: WalletHistoryActions,
  superInvoice: SuperInvoiceActions,
  tradeOffers: TradeOffersActions,
  invoiceHistory: InvoiceHistoryActions,
  downloadCentre: DownloadCentreActions,
  postPay: PostPayActions,
  kpiReport: KpiReportActions,
  contractStatus: ContractStatusActions,
  portInResubmit: PortInResubmitActions,
  simRegAssessment: SimRegAssessmentActions,
  faq: FaqActions,
  sigaSimReg: SigaSimRegAction,
  employees: EmployeeActions,
  prepaidPortal: PrepaidPortalActions,
  migration: MigrationAction,
  simonly: SimOnlyActions
}

export default configureModules;
