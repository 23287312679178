import 'react-app-polyfill/stable';
import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import configureModules from './modules';
import configureServices, { IServices } from './services';
import i18n from './i18n';
import ServiceRegistry, { HttpService } from './context/serviceRegistry';
import { ThemeProvider } from '@material-ui/core';
import { theme } from './theme';

const history = createBrowserHistory()

const loadApp = async () => {
  const module = await import('./App')
  return module.default
}

const render = async (store: any, services: any) => {
  const App = await loadApp()

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ServiceRegistry.Provider value={{ httpService: new HttpService(services) }}>
            <ThemeProvider theme={theme}>
              <App/>
            </ThemeProvider>
          </ServiceRegistry.Provider>
        </ConnectedRouter>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  )

}

(async function init() {
  const services = configureServices()
  const store = await configureModules(services, history, i18n)

  render(store, services)
})()


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
