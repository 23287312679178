import { IServices } from '@src/services';
import initReducer from './reducers';
import initActions from './actions';

export interface SimoBundleProduct {
  country: string;
  offerId: string;
  validityType: string;
  validity: number;
  dataSpeed: string;
  dataLimit: string;
  name: string;
  type: string;
  cost: number;
  ProductDescription: string;
  ProductTitle: string;
}

export interface SimoBundlePurchase {
  RetailerId: string;
  Country: string;
  Reference: string;
  ProductId: string;
  Email: string;
  Msisdn: string;
  OrderId: string;
  Amount: number;
  DiscountedAmount: number;
  CreatedOn: string;
  FulfilledOn: string;
  EpcPromotionId: string;
  Status: string;
  IsInitiated: boolean;
  Exception: string;
}

const configureSimOnlyModule = (services: IServices) => {
  const actions = initActions(services.simonlyService, services.storageService);
  const reducer = initReducer(services.storageService);

  return { actions, reducer };
};

export type SimOnlyReducer = ReturnType<typeof initReducer>
export type SimOnlyActions = ReturnType<typeof initActions>

export default configureSimOnlyModule;