import { IStorageService } from "@src/services/StorageService";
import { IAction, ActionType } from "../types";
import moment from 'moment';
import { SimoBundleProduct, SimoBundlePurchase } from ".";

const initReducer = function (storageService: IStorageService) {
    let INITIAL_STATE = {        
        sessionId: undefined as string | undefined,
        validateMsisdnError: undefined as string | undefined,
        products: [] as SimoBundleProduct[],
        history: [] as SimoBundlePurchase[],
        isFetchingHistory: false,
        isFetchingSessionId: false,
        isMsisdnValidated: false,
        isValidatingMsisdn: false,
        isFetchingProducts: false,
        isInitiatingPurchase: false,
        isDownloading: false,
        purchasePayload: {
            MSISDN: undefined as string | undefined,
            Email: undefined as string | undefined,
            ProductId: undefined as string | undefined,
            Language: undefined as string | undefined
        },
        historySearchParams: {
            fromDate: moment().subtract(7, 'days').format('yyyy-MM-DD') as string | undefined,
            toDate: moment().format('yyyy-MM-DD') as string | undefined,
            msisdn: '',
            status: 'All' as 'All' | 'Success' | 'Failed'
        }
    }

    type SimOnlyState = typeof INITIAL_STATE
    type SimOnlyPurchasePayload = typeof INITIAL_STATE.purchasePayload
    type HistorySearchParams = typeof INITIAL_STATE.historySearchParams

    let reducersByActions: any = {}

    reducersByActions[ActionType.SimOnlyProductsRequest] = (state: SimOnlyState): SimOnlyState => {
        return {
            ...state,
            isFetchingProducts: true
        }
    }

    reducersByActions[ActionType.SimOnlyProductsResponse] = (state: SimOnlyState, actionData: any): SimOnlyState => {
        return {
            ...state,
            isFetchingProducts: false,
            products: actionData ?? []
        }
    }

    reducersByActions[ActionType.SimOnlySessionRequest] = (state: SimOnlyState): SimOnlyState => {
        return {
            ...state,
            isFetchingSessionId: true
        }
    }

    reducersByActions[ActionType.SimOnlySessionResponse] = (state: SimOnlyState, actionData: string | undefined): SimOnlyState => {
        return {
            ...state,
            isFetchingSessionId: false,
            sessionId: actionData
        }
    }

    reducersByActions[ActionType.SimOnlyUpdatePurchasePayload] = (state: SimOnlyState, actionData: Partial<SimOnlyPurchasePayload>): SimOnlyState => {
        const isMsisdnChanged = Object.keys(actionData).includes('MSISDN') && actionData.MSISDN !== state.purchasePayload.MSISDN
        return {
            ...state,
            isMsisdnValidated: isMsisdnChanged ? false : state.isMsisdnValidated,
            validateMsisdnError: isMsisdnChanged ? undefined : state.validateMsisdnError,
            purchasePayload: {
                ...state.purchasePayload, ...actionData
            }
        }
    }

    reducersByActions[ActionType.SimOnlyValidateMsisdnRequest] = (state: SimOnlyState): SimOnlyState => {
        return {
            ...state,
            isValidatingMsisdn: true
        }
    }

    reducersByActions[ActionType.SimOnlyValidateMsisdnResponse] = (state: SimOnlyState, actionData: { success: boolean, errorCode: string | undefined }): SimOnlyState => {
        return {
            ...state,
            isValidatingMsisdn: false,
            isMsisdnValidated: actionData.success,
            validateMsisdnError: actionData.errorCode
        }
    }

    reducersByActions[ActionType.SimOnlyHistoryRequest] = (state: SimOnlyState): SimOnlyState => {
        return {
            ...state,
            isFetchingHistory: true
        }
    }

    reducersByActions[ActionType.SimOnlyHistoryResponse] = (state: SimOnlyState, actionData: any): SimOnlyState => {
        return {
            ...state,
            isFetchingHistory: false,
            history: actionData ?? []
        }
    }

    reducersByActions[ActionType.SimOnlyUpdateHistorySearchParams] = (state: SimOnlyState, actionData: Partial<HistorySearchParams>): SimOnlyState => {
        return {
            ...state,
            historySearchParams: {
                ...state.historySearchParams,
                ...actionData
            }
        }
    }

    reducersByActions[ActionType.SimOnlyCleanHistoryData] = (state: SimOnlyState): SimOnlyState => {
        return {
            ...state,
            historySearchParams: { ...INITIAL_STATE.historySearchParams },
            history: []
        }
    }

    reducersByActions[ActionType.SimOnlyStartDownload] = (state: SimOnlyState, actionData: any): SimOnlyState => {
        return {
          ...state,
          isDownloading: true
        }
    }
    reducersByActions[ActionType.SimOnlyEndDownload] = (state: SimOnlyState, actionData: any): SimOnlyState => {
        return {
            ...state,
            isDownloading: false
        }
    }

    reducersByActions[ActionType.SimOnlyCleanData] = 
    reducersByActions[ActionType.Logout] = (): SimOnlyState => {
        return {
            ...INITIAL_STATE,
            purchasePayload: { ...INITIAL_STATE.purchasePayload }
        }
    }    

   


    const reducer = (state = JSON.parse(JSON.stringify((INITIAL_STATE))), action: IAction): SimOnlyState => {        
        return reducersByActions[action.type] !== undefined ? reducersByActions[action.type](state, action.data) : state;
    };

    return reducer;
}

export default initReducer