import { map } from "rxjs/operators"
import config from '../../env'
import { ajaxType } from "../interceptor"

const SimOnlyService = (ajax: ajaxType) => {
    return {
        getSessionId: () => {
            return ajax.get(`${config.url.API_URL}PostPayPortIn/GetSessionId`, {
                'Content-Type': 'text/plain; charset=utf-8',
            }, 'text')
                .pipe(map(res => res.response));
        },
        validateMsisdn: (sessionId: string, msisdn: string) => {
            return ajax.get(`${config.url.API_URL}PostPayPortIn/ValidateMsisdn/${msisdn}/${sessionId}`)
                .pipe(map(res => res.response));
        },
        fetchProducts: (locale: string) => {
            return ajax.get(`${config.url.API_URL}SimoPurchase/Product/${locale}`)
                .pipe(map(res => res.response));
        },
        fetchHistory: (filters: { fromDate?: string; toDate?: string; msisdn?: string; status?: string }) => {
            const filterString = Object.keys(filters).reduce((acc, curr) => {
                if (!!filters[curr as keyof typeof filters]) {
                    acc += `${!!acc ? '&' : ''}${curr}=${filters[curr as keyof typeof filters]}`
                }
                return acc
            }, '')
            return ajax.get(
                `${config.url.API_URL}SimoPurchase/History?${filterString}`
            ).pipe(map(res => res.response));
        },
        purchaseSim: (payload: {
            MSISDN: string | undefined;
            Email: string | undefined;
            ProductId: string | undefined;
        }, locale: string) => {
            return ajax.post(
                `${config.url.API_URL}SimoPurchase/Initiate/${locale}`,
                payload,
                { "Content-Type": "application/json" }
            ).pipe(map(res => res.response));
        },
        downloadHistory: (filters: any, locale: string) => {
            return ajax.download(`${config.url.API_URL}SimoPurchase/Download/${locale}?${Object.keys(filters)
                .filter(el => filters[el])
                .map((key) => key + "=" + encodeURIComponent(filters[key]))
                .join('&')}`,
                "get"
            ).pipe(map(res => res.response))
        }
    }
}

export type ISimOnlyService = ReturnType<typeof SimOnlyService>

export default SimOnlyService;